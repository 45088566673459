export default {
  props: ['type', 'dialogVisible', 'id', 'getList'],
  emits: ['hide-dialog'],
  data() {
    return {
      visible: this.dialogVisible,
      defaultProps: {
        children: 'children',
        label: 'name',
        disabled: 'disabled'
      },
      roleForm: {
        name: '',
        privilege_ids: []
      },
      privilegeList: []
    };
  },
  mounted() {
    this.getPrivilegeList();
    if (this.type === 'edit') {
      this.getRole();
    }
  },
  methods: {
    async getPrivilegeList() {
      this.$store.commit('system/setPrivilegeLoading', true);
      try {
        await this.$store.dispatch('system/getPrivilegeList');
        this.privilegeList = this.$store.state.system.privilegeList;
      } catch (err) {
        this.$store.commit('system/setPrivilegeLoading', false);
        return;
      }
    },
    async createRole(body) {
      try {
        await this.$store.dispatch('system/createRole', body);
        this.visible = false;
        this.getList();
      } catch (err) {
        return;
      }
    },
    async updateRole(val) {
      let body = val;
      body['id'] = this.id;
      try {
        await this.$store.dispatch('system/updateSystemRole', body);
        this.visible = false;
        this.getList();
      } catch (err) {
        return;
      }
    },
    async getRole() {
      try {
        await this.$store.dispatch('system/getSystemRole', {
          params: {
            id: this.id
          }
        });
        this.roleForm = this.$store.state.system.systemRole;
      } catch (err) {
        return;
      }
    },
    cancel() {
      this.visible = false;
      this.$emit('hide-dialog', this.visible);
    },
    submitRoleForm() {
      this.$refs.roleForm.validate(valid => {
        if (valid) {
          if (this.type === 'create') {
            this.createRole(this.roleForm);
          } else {
            this.updateRole(this.roleForm);
          }
        }
      });
    }
  }
};