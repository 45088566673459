import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  style: {
    "text-align": "right"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_tree_select = _resolveComponent("el-tree-select");
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_form = _resolveComponent("el-form");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $data.visible,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.visible = $event),
    width: "25%",
    "close-on-click-modal": false,
    onClose: $options.cancel
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form, {
      ref: "roleForm",
      model: $data.roleForm,
      "label-width": "100px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        label: "角色名称",
        prop: "name",
        rules: {
          required: true,
          message: '请输入角色名称'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $data.roleForm.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.roleForm.name = $event),
          placeholder: "请输入角色名称",
          clearable: ""
        }, null, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "权限",
        prop: "privilege_ids",
        rules: [{
          required: true,
          message: '请选择权限'
        }]
      }, {
        default: _withCtx(() => [_createVNode(_component_el_tree_select, {
          modelValue: $data.roleForm.privilege_ids,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.roleForm.privilege_ids = $event),
          data: $data.privilegeList,
          multiple: "",
          clearable: "",
          "collapse-tags": "",
          "check-strictly": "",
          props: $data.defaultProps
        }, null, 8, ["modelValue", "data", "props"])]),
        _: 1
      }), _createVNode(_component_el_divider), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_button, {
        class: "close-btn",
        onClick: $options.cancel
      }, {
        default: _withCtx(() => [_createTextVNode(" 取消 ")]),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_el_button, {
        type: "primary",
        onClick: $options.submitRoleForm
      }, {
        default: _withCtx(() => [_createTextVNode(" 提交 ")]),
        _: 1
      }, 8, ["onClick"])])]),
      _: 1
    }, 8, ["model"])]),
    _: 1
  }, 8, ["modelValue", "onClose"]);
}