import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b8e17fac"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "section-border"
};
const _hoisted_2 = {
  class: "flex-between"
};
const _hoisted_3 = {
  class: "select-title"
};
const _hoisted_4 = {
  class: "position-right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_table = _resolveComponent("el-table");
  const _component_role_form = _resolveComponent("role-form");
  const _component_base_confirm = _resolveComponent("base-confirm");
  const _component_el_pagination = _resolveComponent("el-pagination");
  const _component_base_option = _resolveComponent("base-option");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock("section", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_divider, {
    direction: "vertical"
  }), _createTextVNode(" 角色列表 ")]), _createVNode(_component_el_button, {
    type: "primary",
    onClick: $options.showCreateDialog
  }, {
    default: _withCtx(() => [_createTextVNode(" 新增角色 ")]),
    _: 1
  }, 8, ["onClick"])]), _withDirectives((_openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_table, {
    stripe: "",
    border: "",
    data: $data.roleList,
    "empty-text": "无数据",
    "header-cell-style": {
      background: '#eef1f6',
      color: '#606266'
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      label: "角色名称",
      prop: "name"
    }), _createVNode(_component_el_table_column, {
      label: "操作"
    }, {
      default: _withCtx(scope => [_createVNode(_component_el_button, {
        type: "danger",
        class: "close-btn",
        onClick: $event => $options.showDeleteRole(scope.row.id)
      }, {
        default: _withCtx(() => [_createTextVNode(" 删除 ")]),
        _: 2
      }, 1032, ["onClick"]), _createVNode(_component_el_button, {
        onClick: $event => $options.showViewDialog(scope.row.id, true)
      }, {
        default: _withCtx(() => [_createTextVNode(" 查看成员 ")]),
        _: 2
      }, 1032, ["onClick"]), _createVNode(_component_el_button, {
        type: "primary",
        onClick: $event => $options.showEditDialog(scope.row.id)
      }, {
        default: _withCtx(() => [_createTextVNode(" 编辑角色 ")]),
        _: 2
      }, 1032, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["data"])])), [[_directive_loading, _ctx.$store.state.system.systemRoleLoading]]), $data.createVisible ? (_openBlock(), _createBlock(_component_role_form, {
    key: 0,
    title: "新增角色",
    type: "create",
    "dialog-visible": $data.createVisible,
    "get-list": $options.getRoleList,
    "close-on-click-modal": false,
    onHideDialog: $options.closeCreateDialog
  }, null, 8, ["dialog-visible", "get-list", "onHideDialog"])) : _createCommentVNode("", true), $data.editVisible ? (_openBlock(), _createBlock(_component_role_form, {
    key: 1,
    id: $data.roleId,
    title: "编辑角色",
    type: "edit",
    "dialog-visible": $data.editVisible,
    "get-list": $options.getRoleList,
    "close-on-click-modal": false,
    onHideDialog: $options.closeEditDialog
  }, null, 8, ["id", "dialog-visible", "get-list", "onHideDialog"])) : _createCommentVNode("", true), $data.confirmDialog ? (_openBlock(), _createBlock(_component_base_confirm, {
    key: 2,
    "dialog-visible": $data.confirmDialog,
    content: "确定要删除该角色吗",
    onGetVisible: $options.closeConfirmDialog,
    onConfirmDeletion: $options.deleteRole
  }, null, 8, ["dialog-visible", "onGetVisible", "onConfirmDeletion"])) : _createCommentVNode("", true)]), _createVNode(_component_base_option, {
    modelValue: $data.viewMemberDialogVisible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.viewMemberDialogVisible = $event),
    title: "查看成员",
    width: "45%",
    onCloseDialog: $options.closeViewDialog
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table, {
      data: $data.memberList,
      border: "",
      stripe: "",
      "empty-text": "无数据",
      "header-cell-style": {
        background: '#eef1f6',
        color: '#606266'
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_table_column, {
        prop: "name",
        label: "姓名",
        width: "100"
      }), _createVNode(_component_el_table_column, {
        prop: "department",
        label: "所属部门"
      }), _createVNode(_component_el_table_column, {
        label: "状态",
        width: "100",
        fixed: "right"
      }, {
        default: _withCtx(scope => [_createElementVNode("div", {
          style: _normalizeStyle({
            color: $options.adminStateColor(scope.row.state)
          })
        }, _toDisplayString(scope.row.state_desc), 5)]),
        _: 1
      })]),
      _: 1
    }, 8, ["data"]), _createElementVNode("div", _hoisted_4, [_ctx.$store.state.system.roleRelatedMemberListLength > $data.pageSize ? (_openBlock(), _createBlock(_component_el_pagination, {
      key: 0,
      total: _ctx.$store.state.system.roleRelatedMemberListLength,
      "current-page": $data.currentPage,
      "page-size": $data.pageSize,
      "page-sizes": [10, 20, 30, 40],
      small: "",
      background: "",
      layout: "total, sizes, prev, pager, next, jumper",
      onSizeChange: $options.changePageSize,
      onCurrentChange: $options.changeCurrentPage
    }, null, 8, ["total", "current-page", "page-size", "onSizeChange", "onCurrentChange"])) : _createCommentVNode("", true)])]),
    _: 1
  }, 8, ["modelValue", "onCloseDialog"])]);
}