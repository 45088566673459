import RoleForm from './common/role-form.vue';
import { adminStateColor } from '../../../utils/index.js';
export default {
  components: {
    RoleForm
  },
  data() {
    return {
      createVisible: false,
      editVisible: false,
      confirmDialog: false,
      viewMemberDialogVisible: false,
      roleId: 0,
      deleteId: 0,
      roleList: [],
      currentPage: 1,
      pageSize: 10,
      memberList: []
    };
  },
  mounted() {
    this.getRoleList();
  },
  methods: {
    adminStateColor,
    async getRoleList() {
      this.$store.commit('system/setSystemRoleLoading', true);
      try {
        await this.$store.dispatch('system/getSystemRoleList');
        this.roleList = this.$store.state.system.systemRoleList;
      } catch (err) {
        this.$store.commit('system/setSystemRoleLoading', false);
        return;
      }
    },
    async deleteRole() {
      try {
        await this.$store.dispatch('system/deleteRole', {
          id: this.deleteId
        });
        this.confirmDialog = false;
        this.getRoleList();
      } catch (err) {
        return;
      }
    },
    showCreateDialog() {
      this.createVisible = true;
    },
    closeCreateDialog() {
      this.createVisible = false;
    },
    showEditDialog(id) {
      this.editVisible = true;
      this.roleId = id;
    },
    closeEditDialog() {
      this.editVisible = false;
    },
    showDeleteRole(id) {
      this.confirmDialog = true;
      this.deleteId = id;
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
    },
    async getRoleRelatedMemberList(id, showDialog) {
      try {
        let params = {
          role_id: id,
          current_page: this.currentPage,
          page_size: this.pageSize
        };
        await this.$store.dispatch('system/getRoleRelatedMemberList', {
          params
        });
        this.memberList = this.$store.state.system.roleRelatedMemberList;
        if (showDialog) {
          this.viewMemberDialogVisible = true;
        }
      } catch (err) {
        return;
      }
    },
    showViewDialog(id, showDialog) {
      this.roleId = id;
      this.currentPage = 1;
      this.pageSize = 10;
      this.getRoleRelatedMemberList(id, showDialog);
    },
    closeViewDialog() {
      this.viewMemberDialogVisible = false;
    },
    changePageSize(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getRoleRelatedMemberList(this.roleId, false);
    },
    changeCurrentPage(val) {
      this.currentPage = val;
      this.getRoleRelatedMemberList(this.roleId, false);
    }
  }
};